import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common'
import { DatePipe, Location } from '@angular/common';
import { PagerService } from '../../services/pager.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-admin-recruitment',
  templateUrl: './admin-recruitment.component.html',
  styleUrls: ['./admin-recruitment.component.css']
})
export class AdminRecruitmentComponent implements OnInit {
  getallRecruitment:any=[];
  adminRecruitmentForm: any;
  publishingdate:any;
  closingdate: any;
  recruitmentdocfile: File | undefined;
  preview: boolean = false;
  msgShow: any;
  displayStyleAdd = "none";
  displayStyleEdit = "none";
  displayStylePlus = "none";
  userid: any;
  fileRecruitment:any;
  
  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';

  id:any;
  invalid:boolean=false;
  isSaved: boolean =false;
  isUpdate: boolean =false;
  constructor(private apiService:ApiService,
    private router:Router,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private pagerService: PagerService,
    private motifyService: NotificationService) { 
      this.initForm();
    }

  ngOnInit(): void {
    this.Recruitmentgetall(this.currentPage);
    if(localStorage.getItem('action') == 'updated'){
      this.toastrService.success('Recruitment updated successfully');
      localStorage.removeItem('action');
    } 
    if(localStorage.getItem('action') == 'saved'){
      this.toastrService.success('Recruitment added successfully');
      localStorage.removeItem('action');
    } 
  }
  
  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.Recruitmentgetall(this.currentPage);
  }
  Recruitmentgetall(currentPage: number){
    let param = {
      // "filter": this.searchItem,
      // "order": "subject",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "subject",
      "limit": this.pageSize,
      "page": currentPage
    }
    //this.SpinnerService.show(); 
    this.apiService.adminRecruitmentgetall(param).subscribe((res:any )=> {
      if (res.result == "Success") {
        this.getallRecruitment = res.data.recruitments;
        //this.SpinnerService.hide(); 
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
        
      }
      else{
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);

      }
   },
   (error:any )=> {
   });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.getallRecruitment.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }


  onSelectFile(event:any){
		if (event.target.files.length > 0) {
			if (event.target.files[0].size > 20*1024*1024) {
        this.toastrService.error('File size must not exceed 20 MB', 'Error!');
			  event.target.value = null;
			}
			else { 
        this.preview = true;
				this.fileRecruitment = <File>event.target.files[0]; 
        this.f1.fileRecruitment.setValue(this.fileRecruitment);
			}
		}
	} 
  get f1() { return this.adminRecruitmentForm.controls; }

  initForm() {
    this.adminRecruitmentForm = this.formBuilder.group({
      subject: ['', Validators.required],
      publishingdate: ['', Validators.required],
      memono: ['', Validators.required],
      closingdate: ['', Validators.required],
      ispublished: [''],
      fileRecruitment:['',Validators.required]
    });
  }
  Submit() {
    if (this.adminRecruitmentForm.invalid || (this.f1.publishingdate.value > this.f1.closingdate.value)) {
      this.invalid=true;
      return;
    }
  else{
    let profileFD = new FormData();
    this.userid = localStorage.getItem('userid');
    if(this.fileRecruitment)
    {      
      profileFD.append('file', this.f1.fileRecruitment.value)
    }
    profileFD.append("subject", this.f1.subject.value)
    profileFD.append("memono", this.f1.memono.value)
    profileFD.append("publishingdate", this.f1.publishingdate.value)
    profileFD.append("closingdate", this.f1.closingdate.value)
    profileFD.append("isPublished", this.f1.ispublished.value)
    profileFD.append("createdBy", this.userid)
    this.apiService.adminRecruitmentadd(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        localStorage.setItem('action', 'saved');
        window.location.reload();
        //this.toastrService.success('Recruitment added successfully'); 
        
      }
    },
      (error: any) => {
        this.toastrService.error('Recruitment not added successfully');
        this.isSaved = false;
      });
    }
    this.displayStyleAdd = "none";
  }
  openPopupEdit(id:any) {
    this.id = id;
    this.apiService.adminRecruitmentId(id).subscribe((res:any )=> {
      if(res.result == "Success"){
        console.log('data :', res.data)
        if(res.data.fileName){
          this.preview = true;
        }
        this.f1.subject.setValue(res.data.subject);
        this.f1.memono.setValue(res.data.memoNo);
        this.f1.publishingdate.setValue(this.datePipe.transform(new Date(res.data.publishingDate), 'yyyy-MM-dd'))
        //this.f1.closingdate.setValue(res.data.closingdate);
        this.f1.closingdate.setValue(this.datePipe.transform(new Date(res.data.closingDate), 'yyyy-MM-dd'))
        this.f1.ispublished.setValue(res.data.isPublished);
      }
   },
   (error:any )=> {
    
   });
   this.displayStyleEdit = "block";
 
  }
  Update(){
    let profileFD = new FormData();
    if(this.fileRecruitment
      ){profileFD.append('file',this.fileRecruitment,this.fileRecruitment.name);}
    profileFD.append("subject",this.f1.subject.value)
    profileFD.append("memono", this.f1.memono.value)
    profileFD.append("publishingDate", this.f1.publishingdate.value)
    profileFD.append("isPublished", this.f1.ispublished.value)
    profileFD.append("closingdate", this.f1.closingdate.value)
    profileFD.append("id",this.id)
  
    this.apiService. adminRecruitmentUpdate(profileFD).subscribe((res: any) => {
      if (res.result == "Success") { 
        //this.motifyService.showSuccess('Recruitment Update', 'Recruitment updated successfully');
        
        localStorage.setItem('action', 'updated');
        window.location.reload();        
        //this.toastrService.success('Recruitment updated successfully');
        
      }
    },
      (error: any) => {
        this.toastrService.error("Recruitment not updated successfully");
      });
  }

  closePopupAdd() {
    this.displayStyleAdd = "none";
  }
  openPopupAdd() {
    let boolean=false;
    this.displayStyleAdd = "block";
    this.f1.subject.setValue(null);
    this.f1.memono.setValue(null);
    this.f1.publishingdate.setValue(null);
    this.f1.closingdate.setValue(null);
    this.f1.ispublished.setValue(null);
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }  
  
  ShowDoc() {
    this.apiService.viewRecruitmentFile(this.id).subscribe((res: any) => {
      if (res) {
        var mediaType = 'application/pdf';
        var blob = new Blob([res], { type: mediaType });
        var fileURL = URL.createObjectURL(blob);
        //console.log("The Url is ", fileURL);
        window.open(fileURL);
      }
      if(res.status='500'){
          console.log("File is not available");          
      }
    }, errMsg => {
      console.log(errMsg);
      // this.toastr.errorToastr(errMsg, 'ERROR');
    });
  }

  SelectPublishingDate(event: any) {
    this.publishingdate = event.target.value;
    if (this.publishingdate > this.closingdate) {
      this.f1.publishingdate.value = "";
      this.publishingdate = null;
      alert("Publishing Date should be less than closing date");
    }
  }
  SelectClosingDate(event: any) {
    this.closingdate = event.target.value;
    if (this.publishingdate > this.closingdate) {
      this.f1.closingdate.value = "";
      this.closingdate = null;
      alert("Closing Date should be greater than publising date");
    }
  } 

}
