<app-header></app-header>
<div class="main-wapper">
  <div class="wapper-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
        <div class="top-panel">
          <form>
            <div class="form-group">
              <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
              <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
            </div>
          </form>

          <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" aria-hidden="true" title="Add Page"
              (click)="openPopupAdd()"></i></a>
        </div>
        <div class="bottom-panel">
          <div class="table-responsive">
            <table class="table table-striped jambo_table">
              <thead>
                <tr style="color: black;">
                  <!-- <button type="button" class="btn primary">testBootstrap</button> -->
                  <th class="column-title">Sl No</th>
                  <th class="column-title">Subject</th>
                  <th class="column-title">FinancialYear</th>
                  <th class="column-title">PublishingDate</th>
                  <th class="column-title">MemoNo</th>
                  <!-- <th class="column-title">ClosingDate</th> -->
                  <th class="column-title">Type</th>
                  <th class="column-title">Action</th>
                  <!-- <th class="column-title">Action</th> -->
                </tr>
              </thead>
              <tbody *ngFor="let item of tableData; let i = index">
                <td>{{(pager.startIndex -1) + i + 2}}</td>
                <td>{{item.subject}}</td>
                <td>{{item.financialYear}}</td>
                <td>{{item.publishingDate}}</td>
                <td>{{item.memoNo}}</td>
                <!-- <td>{{item.closingDate}}</td> -->
                <td *ngIf="item.type === 'C'">
                  Circular
                </td>
                <td *ngIf="item.type === 'N'">
                  Notice
                </td>

                <td class="last">
                  <div class="action-button">
                    <!-- <a class="plus-btn"><i class="fa fa-plus" aria-hidden="true" (click)="openPopupPlus()"
                        title="View"></i></a> -->
                    <a class="edit-btn"><i class="fa fa-edit" aria-hidden="true" (click)="openPopupEdit(item.id)"
                        title="Edit"></i></a>
                    <!-- <a class="delete-btn"><i class="fa fa-trash" aria-hidden="true" (click)="Delete(item.id, item.fileName)"></i></a> -->
                  </div>
                </td>
              </tbody>
            </table>
          </div>
<!-- #region  Pagination-->
<div class="row">
  <div class="col-md-5">
    <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to {{pager.endIndex+1}}
      of {{totalCount}} entries</div>
  </div>

  <div class="col-md-7">
    <div class="dataTables_paginate paging_simple_numbers">
      <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
        <li class="paginate_button previous disabled" ng-disabled="true"
          [ngClass]="{'disabled':pager.currentPage === 1}">
          <a aria-controls="datatable-fixed-header" (click)="getallNotice(1)">First</a>
        </li>
        <li class="paginate_button previous disabled" [ngClass]="{disabled:pager.currentPage === 1}">
          <a aria-controls="datatable-fixed-header"
            (click)="getallNotice(pager.currentPage - 1)">Previous</a>
        </li>
        <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
          [ngClass]="{active:pager.currentPage === page}">
          <a aria-controls="datatable-fixed-header" (click)="getallNotice(page)">{{page}}</a>
        </li>
        <li class="paginate_button previous disabled"
          [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
          <a aria-controls="datatable-fixed-header"
            (click)="getallNotice(pager.currentPage + 1)">Next</a>
        </li>
        <li class="paginate_button previous disabled"
          [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
          <a aria-controls="datatable-fixed-header" (click)="getallNotice(pager.totalPages)">Last</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- #endregion -->
          
          
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait... </p>
</ngx-spinner>


<!---pop-new-poroject-->

<div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupAdd()" class="cls-new-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Add Notice</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form" [formGroup]="adminNoticeForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject *</label>
              <input id="form_name" type="text" name="subject" formControlName="subject" class="form-control">
              <div *ngIf="f1.subject.invalid && (f1.subject.dirty || f1.subject.touched)">
                <div *ngIf="f1.subject.errors.required" style="color: red;">
                  Please enter subject
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Financial year *</label>
              <input id="form_route" type="text" name="financial" formControlName="financialyear" class="form-control">
              <div *ngIf="f1.financialyear.invalid && (f1.financialyear.dirty || f1.financialyear.touched)">
                <div *ngIf="f1.financialyear.errors.required" style="color: red;">
                  Please enter financialyear
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Memo No</label>
              <input id="form_route" type="text" name="memo" formControlName="memono" class="form-control">
              <div *ngIf="f1.memono.invalid && (f1.memono.dirty || f1.memono.touched)">
                <div *ngIf="f1.memono.errors.required" style="color: red;">
                  Please enter memono
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">Date of Publishing</label>
              <input type="date" name="begin" value="" formControlName="publishingdate"
              (change)="SelectPublishingDate($event)" [(ngModel)]="publishingdate">
              <div *ngIf="f1.publishingdate.invalid && (f1.publishingdate.dirty || f1.publishingdate.touched)">
                <div *ngIf="f1.publishingdate.errors.required" style="color: red;">
                  Please enter publishingdate
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">Date of Closing</label>
              <input id="form_email" type="date" name="closing" formControlName="closingdate" class="form-control"
              (change)="SelectClosingDate($event)" [(ngModel)]="closingdate">
              <div *ngIf="f1.closingdate.invalid && (f1.closingdate.dirty || f1.closingdate.touched)">
                <div *ngIf="f1.closingdate.errors.required" style="color: red;">
                  Please enter closingdate
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-md-4">
            <div class="form-group">
              <label>Select File</label>
            <input type="file" (change)="onSelectFile($event)" formControlName="Noticefile" class="file">
            <font color="sky blue">(Maximum 20 MB)</font>
            <div *ngIf="f1.Noticefile.invalid && (f1.Noticefile.dirty || f1.Noticefile.touched)">
              <div *ngIf="f1.Noticefile.errors.required" style="color: red;">
                Please enter Noticefile
              </div>
            </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Type</label>
            <select id="type" name="type" (change)="onSelectType($event)" formControlName="type" class="form-control">
              <option value="N">Notice</option>
              <option value="C">Circular</option>
            </select>
            <div *ngIf="f1.type.invalid && (f1.type.dirty || f1.type.touched)" class="alert">
              <div *ngIf="f1.type.errors.required" style="color: red;">
                Please enter type
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="row">          
          
          <div class="col-md-4"  style="display: none;">
            <div class="form-group">
              <label for="form_cption">Enter mapping type</label>
              <input type="text" name="begin" value="" formControlName="mappingtype" class="form-control">
              <div *ngIf="f1.mappingtype.invalid && (f1.mappingtype.dirty || f1.mappingtype.touched)" class="alert">
                <div *ngIf="f1.mappingtype.errors.required" style="color: red;">
                  Please enter mappingtype
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Is Published</label>&nbsp;
            <input type="checkbox" id="published" name="published" formControlName="ispublished"
              style="width:20px;height:20px;" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          
        </div>
        <!-- <div class="row editor">
              <div class="col-md-12">
                <textarea name="editor1">Type here and show console to see editor's height</textarea>
              </div>
            </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn" (click)="Submit()">Submit</button>
              <button type="button" class="cancl-btn" (click)="closePopupAdd()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait... </p>
</ngx-spinner>



<!---pop-plus Pge-->
<!-- <div class="plus-pop" class="modal" [ngStyle]="{'display':displayStylePlus}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupPlus()" class="cls-plus-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Upload Files</h4>    
    <form id="contact-form" role="form">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject *</label>
              <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter Subject *"
                required="required" data-error="Subject is required.">
              <small class="required">Subject Is Required</small>
            </div>
          </div>
        </div>

        <div class="upload-area-main">
          <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
          <div class="upload-area">
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg1.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
          </div>

          <div class="upaload-btn-area">
            <span><i class="fa fa-file" aria-hidden="true"></i> 2 Files Selected</span>
            <div class="btn-grp">
              <button type="button" class="cancl-btn"><i class="fa fa-trash" aria-hidden="true"></i> Remove</button>
              <button type="button" class="done-btn "><i class="fa fa-folder" aria-hidden="true"></i> Browse</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn ">Upload</button>
              <button type="button" class="cancl-btn" (click)="closePopupPlus()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div> -->

<!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
  <div class="new-pop-body"><a (click)="closePopupEdit()" class="cls-edit-pop">
      <img src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Edit Notice</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form" [formGroup]="adminNoticeForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">subject *</label>
              <input id="form_name" type="text" name="subject" class="form-control" formControlName="subject"
                required="required" data-error="Name is required.">
              <div *ngIf="f1.subject.invalid && (f1.subject.dirty || f1.subject.touched)" class="alert">
                <div *ngIf="f1.subject.errors.required" style="color: red;">
                  Please enter subject
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">financialyear *</label>
              <input id="form_route" type="text" name="financialyear" class="form-control"
                formControlName="financialyear" required="required" data-error="Route is required.">
              <div *ngIf="f1.financialyear.invalid && (f1.financialyear.dirty || f1.financialyear.touched)"
                class="alert">
                <div *ngIf="f1.financialyear.errors.required" style="color: red;">
                  Please enter financialyear
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">memono</label>
              <input id="form_cption" type="text" name="memono" formControlName="memono" class="form-control">
              <div *ngIf="f1.memono.invalid && (f1.memono.dirty || f1.memono.touched)" class="alert">
                <div *ngIf="f1.memono.errors.required" style="color: red;">
                  Please enter memono
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">          
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">publishingdate</label>
              <input id="form_email" type="date" name="publishingdate" formControlName="publishingdate"
                class="form-control" (change)="SelectPublishingDate($event)" [(ngModel)]="publishingdate">
              <div *ngIf="f1.publishingdate.invalid && (f1.publishingdate.dirty || f1.publishingdate.touched)"
                class="alert">
                <div *ngIf="f1.publishingdate.errors.required" style="color: red;">
                  Please enter publishingdate
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">closingdate</label>
              <input id="form_email" type="date" name="closingdate" formControlName="closingdate" class="form-control"
              (change)="SelectClosingDate($event)" [(ngModel)]="closingdate">
              <div *ngIf="f1.closingdate.invalid && (f1.closingdate.dirty || f1.closingdate.touched)" class="alert">
                <div *ngIf="f1.closingdate.errors.required" style="color: red;">
                  Please enter closingdate
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">File *</label>
              <input type="file" (change)="onSelectFile($event)" formControlName="Noticefile" class="file">
              <font color="sky blue">(Maximum 20 MB)</font>
              <div *ngIf="f1.Noticefile.invalid && (f1.Noticefile.dirty || f1.Noticefile.touched)">
                <div *ngIf="f1.Noticefile.errors.required" style="color: red;">
                  Please enter Noticefile
                </div>
              </div>
              <div class="form-group" *ngIf="preview == true">
                <a class="btn btn-primary" (click)="ShowDoc()" target="_blank"
                  style="margin-top: 25px;">Preview Uploaded File</a>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Type</label>
              <select id="type" name="type" (change)="onSelectType($event)" formControlName="type" class="form-control">
                <option value="C">Circular</option>
                <option value="N">Notice</option>
              </select>
              <div *ngIf="f1.type.invalid && (f1.type.dirty || f1.type.touched)" class="alert">
                <div *ngIf="f1.type.errors.required" style="color: red;">
                  Please enter type
                </div>
              </div>
            </div>
          </div>

        </div>        
        <div class="row">          
          
          <div class="col-md-4" style="display: none;">
            <div class="form-group">
              <label for="form_name" style="display: none;">Enter Mappingtype</label>
              <input id="form_name" style="display: none;" type="text" size="25" name="mappingtype" formControlName="mappingtype"
                class="form-control">
              <div *ngIf="f1.mappingtype.invalid && (f1.mappingtype.dirty || f1.mappingtype.touched)"  style="display: none;" class="alert">
                <div *ngIf="f1.mappingtype.errors.required"  style="display: none;" style="color: red;">
                  Please enter mappingtype
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" class="pull-right">
            <div class="form-group">
              <label for="form_name">IsPublished</label>
              <input type="checkbox" id="published" name="published" formControlName="ispublished"
                style="width:20px;height:20px;" class="form-control">
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="btn-grp">
            <button type="button" class="done-btn" (click)="Update()">Update</button>
            <button type="button" (click)="closePopupEdit()" class="cancl-btn">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>