<app-header></app-header>
<div class="main-wapper">
  <div class="wapper-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
        <div class="top-panel">
          <form>
            <div class="form-group">
              <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
              <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
            </div>
          </form>
          <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" (click)="openPopupAdd()"
              aria-hidden="true" title="Add Page"></i></a>
        </div>
      </div>
     
      

    </div>
    <div class="row ">
      <div class="bottom-panel">
       <div class="table-responsive">
        <table class="table table-striped jambo_table ">
          <thead style="position: sticky;top: 0">
            <tr>
              <th style="width: 7%;">Sl No</th>
              <th style="width: 15%;">Publishing Date</th>
              <th style="width: 35%;">Subject</th>
              <th style="width: 13%;">Memo No</th>
              <th style="width: 10%;">Memo Date</th>
              <th style="width: 10%;">Closing Date</th>
              <th style="width: 10%;">Action</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of getallTender;let i=index">
            <tr>
              <td>{{(pager.startIndex -1) + i + 2}}</td>
              <td>{{item.publishingDate}}</td>
              <td>{{item.subject}}</td>
              <td>{{item.memoNo}}</td>
              <td>{{item.memoDate }}</td>
              <td>{{item.closingDate}}</td>
              <td class="last">
                <div class="action-button">
                  <a href="javascript:void(0);" class="edit-btn"><i class="fa fa-edit" (click)="openPopupEdit(item.id)"
                      aria-hidden="true" title="Edit"></i></a>
                </div>
              </td>
            </tr>
    
          </tbody>
        </table>
       </div>

       <!-- #region  Pagination-->
      <div class="row">
        <div class="col-md-5">
          <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to {{pager.endIndex+1}}
            of {{totalCount}} entries</div>
        </div>

        <div class="col-md-7">
          <div class="dataTables_paginate paging_simple_numbers">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
              <li class="paginate_button previous disabled" ng-disabled="true"
                [ngClass]="{'disabled':pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header" (click)="getallTenders(1)">First</a>
              </li>
              <li class="paginate_button previous disabled" [ngClass]="{disabled:pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallTenders(pager.currentPage - 1)">Previous</a>
              </li>
              <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
                [ngClass]="{active:pager.currentPage === page}">
                <a aria-controls="datatable-fixed-header" (click)="getallTenders(page)">{{page}}</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallTenders(pager.currentPage + 1)">Next</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header" (click)="getallTenders(pager.totalPages)">Last</a>
              </li>
            </ul>
          </div>
        </div>
      </div> 
      <!-- #endregion-->

      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait... </p>
</ngx-spinner>
<div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupAdd()" class="cls-new-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Add Tender</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form" [formGroup]="adminTenderForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject *</label>
              <input id="form_name" type="text" name="subject" formControlName="subject" class="form-control"
                required="required" data-error="Name is required.">
              <div *ngIf="f1.subject.invalid && (f1.subject.dirty || f1.subject.touched)" class="alert">
                <div *ngIf="f1.subject.errors.required" style="color: red;">
                  Please enter subject
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Financial year *</label>
              <input id="form_route" type="text" name="financial" formControlName="financialyear" class="form-control"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.financialyear.invalid && (f1.financialyear.dirty || f1.financialyear.touched)"
                class="alert">
                <div *ngIf="f1.financialyear.errors.required" style="color: red;">
                  Please enter financialyear
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Memo No *</label>
              <input id="form_route" type="text" name="memo" formControlName="memono" class="form-control"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.memono.invalid && (f1.memono.dirty || f1.memono.touched)" class="alert">
                <div *ngIf="f1.memono.errors.required" style="color: red;">
                  Please enter memono
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">Date of Publishing *</label>

              <input type="date" name="begin" value="" formControlName="publishingdate"
                (change)="SelectPublishingDate($event)" [(ngModel)]="publishingdate">
              <div *ngIf="f1.publishingdate.invalid && (f1.publishingdate.dirty || f1.publishingdate.touched)"
                class="alert">
                <div *ngIf="f1.publishingdate.errors.required" style="color: red;">
                  Please enter publishingdate
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">Date of Closing *</label>
              <input id="form_email" type="date" name="closing" formControlName="closingdate" class="form-control"
                (change)="SelectClosingDate($event)" [(ngModel)]="closingdate">
              <div *ngIf="f1.closingdate.invalid && (f1.closingdate.dirty || f1.closingdate.touched)" class="alert">
                <div *ngIf="f1.closingdate.errors.required" style="color: red;">
                  Please enter closingdate
                </div>
              </div>
              <p *ngIf="invalid" style="color: red;">Please enter a valid date</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="lblMemoNo">Memo Date *</label>
              <input id="memoNo" type="date" formControlName="txtMemoDate" class="form-txtMemoDate">
              <div *ngIf="f1.txtMemoDate.invalid && (f1.txtMemoDate.dirty || f1.txtMemoDate.touched)" class="alert">
                <div *ngIf="f1.txtMemoDate.errors.required" style="color: red;">
                  Please enter Memo Date
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select File *</label>
              <input type="file" (change)="onSelectFile($event)" formControlName="fileTender" class="file">
              <font color="sky blue">(Maximum 20 MB)</font>
              <div *ngIf="f1.fileTender.invalid && (f1.fileTender.dirty || f1.fileTender.touched)">
                <div *ngIf="f1.fileTender.errors.required" style="color: red;">
                  Please enter fileTender
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label>Is Published</label>&nbsp;
            <input type="checkbox" id="published" name="published" formControlName="ispublished"
              style="width:20px;height:20px;" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn" (click)="Submit()">Submit</button>
              <button type="button" class="cancl-btn" (click)="closePopupAdd()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait... </p>
</ngx-spinner>

<!---pop-plus Pge-->
<div class="plus-pop" class="modal" [ngStyle]="{'display':displayStylePlus}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupPlus()" class="cls-plus-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Upload Files</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject *</label>
              <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter Subject *"
                required="required" data-error="Subject is required.">
              <small class="required">Subject Is Required</small>
            </div>
          </div>
        </div>
        <div class="upload-area-main">
          <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
          <div class="upload-area">
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg1.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is RequiredSubject Is RequiredSubject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
          </div>

          <div class="upaload-btn-area">
            <span><i class="fa fa-file" aria-hidden="true"></i> 2 Files Selected</span>
            <div class="btn-grp">
              <button type="button" class="cancl-btn"><i class="fa fa-trash" aria-hidden="true"></i> Remove</button>
              <button type="button" class="done-btn "><i class="fa fa-folder" aria-hidden="true"></i> Browse</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn ">Upload</button>
              <button type="button" class="cancl-btn" (click)="closePopupPlus()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupEdit()" class="cls-edit-pop"><img
        src="./assets/img/close.png" /></a>
    <h4>Edit Tender</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form" [formGroup]="adminTenderForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject</label>
              <input id="form_name" type="text" name="subject" formControlName="subject" class="form-control"
                required="required" data-error="Name is required.">
              <div *ngIf="f1.subject.invalid && (f1.subject.dirty || f1.subject.touched)" class="alert">
                <div *ngIf="f1.subject.errors.required" style="color: red;">
                  Please enter subject
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Financial year</label>
              <input id="form_route" type="text" name="financial" formControlName="financialyear" class="form-control"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.financialyear.invalid && (f1.financialyear.dirty || f1.financialyear.touched)"
                class="alert">
                <div *ngIf="f1.financialyear.errors.required" style="color: red;">
                  Please enter financialyear
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Memo No</label>
              <input id="form_route" type="text" name="memo" formControlName="memono" class="form-control"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.memono.invalid && (f1.memono.dirty || f1.memono.touched)" class="alert">
                <div *ngIf="f1.memono.errors.required" style="color: red;">
                  Please enter memono
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">Date of Publishing</label>
              <input id="form_cption" type="date" name="publishing" formControlName="publishingdate"
                class="form-control" (change)="SelectPublishingDate($event)" [(ngModel)]="publishingdate">
              <div *ngIf="f1.publishingdate.invalid && (f1.publishingdate.dirty || f1.publishingdate.touched)"
                class="alert">
                <div *ngIf="f1.publishingdate.errors.required" style="color: red;">
                  Please enter publishingdate
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">Date of Closing</label>
              <input id="form_email" type="date" name="closing" formControlName="closingdate" class="form-control"
                (change)="SelectClosingDate($event)" [(ngModel)]="closingdate">
              <div *ngIf="f1.closingdate.invalid && (f1.closingdate.dirty || f1.closingdate.touched)" class="alert">
                <div *ngIf="f1.closingdate.errors.required" style="color: red;">
                  Please enter closingdate
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="lblMemoNo">Memo Date *</label>
              <input type="date" formControlName="txtMemoDate" class="form-txtMemoDate">
              <div *ngIf="f1.txtMemoDate.invalid && (f1.txtMemoDate.dirty || f1.txtMemoDate.touched)" class="alert">
                <div *ngIf="f1.txtMemoDate.errors.required" style="color: red;">
                  Please enter Memo Date
                </div>
              </div>
            </div>
          </div>




        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select File</label>
              <input type="file" (change)="onSelectFile($event)" formControlName="fileTender" class="file">
              <font color="sky blue">(Maximum 20 MB)</font>
              <div *ngIf="f1.fileTender.invalid && (f1.fileTender.dirty || f1.fileTender.touched)">
                <div *ngIf="f1.fileTender.errors.required" style="color: red;">
                  Please enter fileTender
                </div>
              </div>
              <div class="form-group" *ngIf="preview == true">
                <a class="btn btn-primary" (click)="ShowDoc()" target="_blank" style="margin-top: 25px;">Preview
                  Uploaded File</a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>Is Published</label>&nbsp;
            <input type="checkbox" id="published" name="published" formControlName="ispublished"
              style="width:20px;height:20px;" class="form-control">
          </div>

        </div>
        <div class="col-md-12">
          <div class="btn-grp">
            <button type="button" class="done-btn " (click)="Update()">Update</button>
            <button type="button" class="cancl-btn" (click)="closePopupEdit()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>