export class AppSettings {

    //Production
    public static RANAGHAT_API = 'https://ranaghatmunicipality.com:8443/api';
    // public static RANAGHAT_API = 'https:160.187.54.45:8433/api';

    // development
    //public static RANAGHAT_API = 'https://localhost:44362/api';
    // public static RANAGHAT_API = 'http://localhost:57901/api';
    public static TENDER_ARCHIVE = '/Tender/getAllTender/A';
    public static TENDER_CURRENT = '/Tender/getAllTender/C';
    public static TENDER_VIEW = '/Tender/View';
    public static CONTACTUS = '/page/contactus';
    public static CHAIRMAN = '/page/chairman';
    public static ABOUTUS = '/page/townprofile';
    public static VISSION = '/page/vision';
    public static NOTICELIST = '/notice/getnotice';
    public static RTI = '/page/rti';
    public static WORDCOUNCILOR = '/page/wardcouncillor';
    public static VIEWFILE = '/download/view/{noticeId} ';
    public static PHOTOGALLERY = '/gallery/getgallery';
    public static VICEMANCHAIRMAN = '/page/vicechairman';
    public static OTHERINFO = '/page/otherinfo';
    public static WORDWISEINFO = '/page/wardwiseinfo';
    public static GETALLWARD = '/wardInfo/getwardinfo';
    public static GETWARDINFOBYID = '/wardInfo/getwardinfobyid'
    public static RECRUITMENTALL = '/recruitment/getrecruitment';
    public static ADMINGALLERYGETALL = '/admingallery/getall';
    public static GALLERYADD = '/admingallery/addgallery';
    public static GALLERYUPDATE = '/admingallery/updategallery';
    //public static ADMINGALLERYID = '/admingallery/getgallery';
    public static ADMINGALLERYID = '/admingallery/get';
    public static GALLERYDELETE = '/admingallery/delete';
    public static GALLERYREMOVE = '/admingallery/removegallery';
    public static ADMINLOGIN = '/adminlogin/login';
    public static ADMINMENU = '/adminlogin/getmenu';
    public static ADMINPROFILE = '/adminlogin/profile';
    public static ADMINPROFILEADD = '/adminlogin/addprofile';
    public static ADMINPROFILEID = '/adminlogin/getprofile';
    public static ADMINPROFILEUPDATE = '/adminlogin/updateprofile';
    public static WARDALL = '/wardinfo/getallward';
    public static ADMINWARDID = '/wardinfo/getwardinfobyid';
    public static WARDADD = '/wardInfo/addWard';
    public static WARDUPDATE = '/wardInfo/updateWard';
    public static TENDERALL = '/admintender/getall';
    public static TENDERID = '/admintender/gettender';
    public static TENDERADD = '/admintender/addtender';
    public static TENDERUPDATE = '/admintender/updatetender';
    public static ADNOTICEGETALL = '/adminnotice/getall';
    public static ADNOTICE = '/adminnotice/addnotice';
    public static NOTICEUPDATE = '/adminnotice/updatenotice';
    public static ADMINNOTICEID = '/adminnotice/getnotice';
    public static NOTICEDELETE = '/adminnotice/deletenotice';
    public static ADMINEVENTNEWSADD = '/adminnewsevent/addnews/';
    public static ADMINEVENTNEWSGETALL = '/adminnewsevent/getall';
    public static ADMINEVENTNEWSID = '/adminnewsevent/getnews';
    public static ADMINEVENTNEWSUPDATE = '/adminnewsevent/updatenews';
    public static ADPAGEGETALL = '/adminpage/getall';
    public static PAGEADD = '/adminpage/addpage';
    public static PAGEUPDATE = '/adminpage/updatepage';
    public static ADMINPAGEID = '/adminpage/getpage';
    public static PAGEDELETE = '/adminpage/deletepage';
    public static ADRECRUITMENTGETALL = '/adminrecruitment/getall';
    public static RECRUITMENTADD = '/adminrecruitment/addrecruitment';
    public static ADMINRECRUITMENTID = '/adminrecruitment/getrecruitment';
    public static RECRUITMENTUPDATE = '/adminrecruitment/updaterecruitment';
    public static DOWNLOADALL = '/admindownload/getall';
    public static DOWNLOADADD = '/admindownload/addDownload';
    public static ADMINDOWNLOADID = '/admindownload/getdownload';
    public static DOWNLOADUPDATE = '/admindownload/updatedownload';
    public static DOWNLOADDELETE = '/admindownload/deletedownload';
    public static CNGPASSWORD = '/AdminLogin/change-password';
    public static CITYPROFILE = '/Page/cityprofile';
    public static WBRPS = '/Page/wbrps';
    public static MUNICIPALITYORG = '/Page/organization-structure';
    public static WARDDETAILS = '/Page/ward-info';
    public static WARDWISESCHOOLDETAILS = '/Page/school-info';
    public static SLUMPOPULATION = '/Page/slum-pop';
    public static DOWNLOADFILEALL = '/download/getall';
    public static INTERNALAUDITALL = '/admininternalaudit/getall';
    public static INTERNALAUDITID = '/admininternalaudit/getinternalaudit';
    public static INTERNALAUDITADD = '/admininternalaudit/addinternalaudit';
    public static INTERNALAUDITUPDATE = '/admininternalaudit/updateinternalaudit';
    public static INTERNALAUDITFILE = '/admininternalaudit/getallinternalaudit';


}
